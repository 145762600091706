<template>
  <el-dialog  :visible="visible"  :show-close="true" :before-close="beClose" width="26%" class="dup_check_dialog"  :close-on-click-modal="false">
    <div slot="title" class="dialog-footer" style="width:100%;">
      报销查重
    </div>
<!--    <div>-->
<!--      <div style="margin: 0 20px;">-->
<!--        <div style=" display: inline-block;width:35%"><span style="color: red;margin-right: 10px;position: relative;top: 3px;">*</span><span>发票号码</span></div>-->
<!--        <el-input style="width: 65%;margin-bottom: 20px;" v-model="invoiceCode"></el-input>-->
<!--      </div>-->
<!--      <div style="margin: 0 20px;">-->
<!--        <div style=" display: inline-block;width:35%"><span style="color: red;margin-right: 10px;position: relative;top: 3px;">*</span><span>发票代码</span></div>-->
<!--        <el-input style="width: 65%;margin-bottom: 20px;" ></el-input>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div slot="footer" style="text-align: center">-->
<!--      <el-button type="primary" @click="doDupCheck">查重</el-button>-->
<!--    </div>-->


    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="发票代码" prop="invoiceCode">
        <el-input v-model="ruleForm.invoiceCode"></el-input>
      </el-form-item>
      <el-form-item label="发票号码" prop="invoiceNo">
        <el-input v-model="ruleForm.invoiceNo"></el-input>
      </el-form-item>

      <div style="text-align: center;padding-bottom:20px;">
        <el-button type="primary" @click="doDupCheck('ruleForm')">查重</el-button>
      </div>
    </el-form>


  </el-dialog>
</template>

<script>
import Dialog from "@/components/Dialog";

export default {
  props: {
    visible: {
      type: Boolean,
    },
  },
  components: {
    Dialog,
  },
  data() {
    return {
      ruleForm: {
        invoiceCode: '',
        invoiceNo: '',
      },
      rules: {
        invoiceCode: [
          { required: true, message: '请输入发票代码', trigger: 'blur' }
        ],
        invoiceNo: [
          { required: true, message: '请输入发票号码', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    httpRequest(param) {
      console.log(param.file);
    },
    beClose(){
      this.$emit('cancelDialog');
    },
    doDupCheck(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("dupCheckDuplicate",{
            companyId: this.getToken('companyId'),
            invoiceCode: this.ruleForm.invoiceCode,
            invoiceNo: this.ruleForm.invoiceNo,
          }).then(res => {
            if (res.success) {
              if(!res.data.duplicated){
                einvAlert.warning("提示","此发票不存在报销记录！",true,'知道了')
              }else{
                einvAlert.warning("提示","<p  style='text-align: left;margin-left:80px'>此发票已被报销！</br> 报销人："+res.data.reimburseName+"</br> 报销日期："+res.data.reimburseTime.slice(0,10)+"</p>",true,'知道了',true)
              }
            } else {
              einvAlert.error("提示","删除失败")
            }
          }).catch(err => {
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }


  }
};
</script>
<style lang="less">
 .dup_check_dialog{
   .el-dialog__body{
      padding: 20px 40px 0px 40px  !important;
   }
 }
</style>
<style lang="less" scoped>
.firstStep {
  margin: 24px 0 12px;
}
.upload {
  cursor: pointer;
  color: #4f71ff;
}
</style>
